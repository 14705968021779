var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "400",
      },
    },
    [
      _c("v-card", { attrs: { color: "#fafafa" } }, [
        _vm.selectedBatch.status !== "POSTED"
          ? _c(
              "section",
              { staticClass: "ma-0 pa-6" },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c("v-icon", { attrs: { "x-large": "", color: "red" } }, [
                      _vm._v("mdi-trash-can-outline"),
                    ]),
                  ],
                  1
                ),
                _c("v-row", { attrs: { justify: "center" } }, [
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(
                      ' Delete "' + _vm._s(_vm.selectedBatch.name) + '"? '
                    ),
                  ]),
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(
                      " Last Updated: " +
                        _vm._s(
                          _vm.formatDateTime(_vm.selectedBatch.last_update_date)
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(" Warning: This action cannot be undone. "),
                  ]),
                ]),
                _c(
                  "v-row",
                  { staticClass: "pa-4", attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "elevation-1",
                        attrs: { height: "40" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("update:value", false)
                          },
                        },
                      },
                      [_vm._v(" No ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text ml-2",
                        attrs: { color: "#0c67a5", height: "40" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteBatch(_vm.selectedBatch.id)
                          },
                        },
                      },
                      [_vm._v("Yes ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.selectedBatch.status === "POSTED"
          ? _c(
              "section",
              { staticClass: "ma-0 pa-6" },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c("v-icon", { attrs: { "x-large": "", color: "red" } }, [
                      _vm._v("mdi-cancel"),
                    ]),
                  ],
                  1
                ),
                _c("v-row", { attrs: { justify: "center" } }, [
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(" Posted Batches may not be deleted. "),
                  ]),
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(" For more info contact iPro Systems Support. "),
                  ]),
                ]),
                _c(
                  "v-row",
                  { staticClass: "pa-4", attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "elevation-1",
                        attrs: { height: "40" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("update:value", false)
                          },
                        },
                      },
                      [_vm._v(" OK ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }